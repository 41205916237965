<template>
    <div class="content-box">
        <div class="container">
            <el-breadcrumb separator="/">
                <el-breadcrumb-item>协同管理</el-breadcrumb-item>
                <el-breadcrumb-item>供应链协同</el-breadcrumb-item>
                <el-breadcrumb-item>采购任务</el-breadcrumb-item>
            </el-breadcrumb>

            <!-- <div class="button-gutter" v-show="userType=='海装风电'">
      <el-button type="primary" @click="addTask">新增任务</el-button>
    </div> -->
            <el-button class="reBack" v-show="this.buyingTaskId !== undefined" @click="$router.push('/Data/contract')" type="primary"
                >返回</el-button
            >
            <div class="content_table">
                <el-table :data="tableData" border max-height="600">
                    <el-table-column width="50" type="expand">
                        <template slot-scope="scope">
                            <el-table :data="scope['row']['buyingTask']['value']">
                                <el-table-column prop="purchaseRecord.name" label="负责人" align="center"> </el-table-column>
                                <!-- <el-table-column prop="remainingNum" label="发货数量" align="center"> </el-table-column> -->
                                <el-table-column prop="purchaseRecord.num" label="发货数量" align="center"> </el-table-column>
                                <el-table-column prop="purchaseRecord.timeCreated" label="完成时间" align="center"> </el-table-column>
                                <el-table-column label="操作" align="center">
                                    <template slot-scope="sco">
                                        <span>
                                            <el-button style="margin-right: 12px" size="mini" @click="restTask(sco.row, scope)">
                                                编辑
                                            </el-button>
                                        </span>
                                    </template>
                                </el-table-column>
                            </el-table>
                        </template>
                    </el-table-column>
                    <el-table-column prop="buyingTask.materialName" align="center" label="任务名称"> </el-table-column>
                    <el-table-column prop="schedule" align="center" label="任务进度"> </el-table-column>
                      <el-table-column prop="buyingTask.num" align="center" label="总数量"> </el-table-column>
                         <el-table-column prop="buyingTask.makeNum" align="center" label="已发数量"> </el-table-column>
                    <el-table-column prop="buyingTask.timeCreated" align="center" label="任务创建时间"> </el-table-column>
                  <el-table-column prop="userInfo.company" align="center" label="供应商"> </el-table-column>
                  <el-table-column prop="userInfo.name" align="center" label="供应商负责人"> </el-table-column>
                    <el-table-column align="center" label="操作" v-if="userType=='供应商'" >
                        <template slot-scope="scope">
                            <el-button   style="margin-right: 12px" size="mini" @click="restAllTask(scope.row, sco)"> 发货 </el-button
                            ></template
                        >
                    </el-table-column>
                </el-table>
            </div>

            <!-- 新增任务 -->
            <el-dialog title="新增" :visible.sync="dialogVisible" :before-close="Cancel">
                <el-form ref="elForm" :model="taskFrom" label-width="100px" label-position="top">
                    <el-form-item label="采购申请处理状态">
                        <el-radio-group v-model="taskFrom.state" size="medium">
                            <el-radio v-for="item in statusOptions" :key="item.value" :label="item.value" :disabled="item.disabled"
                                >{{ item.label }}
                            </el-radio>
                        </el-radio-group>
                    </el-form-item>

                    <el-form-item label="创建对象的人员名称">
                        <el-input v-model="taskFrom.personName" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>

                    <el-form-item label="物料" label-position="top">
                        <el-input v-model="taskFrom.materialName" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="采购申请数量">
                        <el-input-number v-model="taskFrom.num" placeholder="订单数量" :step="1"></el-input-number>
                    </el-form-item>
                    <el-form-item label="采购申请计量单位">
                        <el-input v-model="taskFrom.measureUnit" clearable :style="{ width: '100%' }"></el-input>
                    </el-form-item>
                    <el-form-item label="所属合同" :style="{ width: '100%' }" required>
                        <el-select :style="{ width: '100%' }" v-model="taskFrom.contractId" placeholder="请选择">
                            <el-option v-for="item in contraOptions" :key="item.value" :label="item.lable" :value="item.value"> </el-option>
                        </el-select>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="dialog-footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="SendTaskData(taskFrom)">确 定</el-button>
                </div>
            </el-dialog>
            <!-- // 编辑任务 -->
            <el-dialog title="编辑" :visible.sync="resetdialogVisible" :before-close="Cancel">
                <el-form ref="elForm" :model="resetFrom" label-width="100px" label-position="top">
                    <el-form-item label="负责人" required>
                        <el-input
                            v-model="resetFrom.name"
                            placeholder="请输入负责人"
                            clearable
                            disabled
                            :style="{ width: '100%' }"
                        ></el-input>
                    </el-form-item>
                    <el-form-item label="任务创建时间" disabled required>
                        <el-date-picker
                            disabled
                            v-model="resetFrom.timeFound"
                            format="yyyy-MM-dd HH:mm"
                            value-format="yyyy-MM-dd HH:mm"
                            :style="{ width: '100%' }"
                            placeholder="请选择开始时间"
                            clearable
                        ></el-date-picker>
                    </el-form-item>
                    <el-form-item label="完成数量"  prop="num">
                        <el-input-number v-model="resetFrom.num" :min="1" :max="maxNum" label="描述文字"></el-input-number>
                    </el-form-item>
                </el-form>
                <div slot="footer" class="footer">
                    <el-button @click="Cancel">取 消</el-button>
                    <el-button type="primary" @click="resetData(resetFrom)">确 定</el-button>
                </div>
            </el-dialog>
            <el_pagination :total="total" :num="num" @page-limit="page_limit"></el_pagination>
        </div>
    </div>
</template>

<script>
import { documentList, taskList, addByingTask, resetByingTask, deleteByingtask, updateByingTaskList, contractlist } from '@/api/document';
import { fileDownload, fileList, fileRemove } from '@/api/file';
import { produceQuery, produceList } from '@/api/produce';

export default {
    name: 'BuyingTask',
    components: {},

    data() {
        return {
            maxNum: null,
            contraOptions: [],
            dialogVisible: false,
            resetdialogVisible: false,
            tableData: [],
            taskFrom: {},
            resetFrom: {},
            data: {},
            userType: localStorage.getItem('userType'),
            statusOptions: [
                {
                    label: '未完成',
                    value: '0'
                },
                {
                    label: '已完成',
                    value: '1'
                }
            ],
            pageSize: 10,
            currentPage: 1,
            total: 0,
            num: 0,
            buyingTaskId: ''
        };
    },
    mounted() {
        this.buyingTaskId = this.$route.query.id;
        console.log(this.buyingTaskId);
        this.getFileList();
        this.getcontractList();
    },
    methods: {
        // 分页子集传过来的数据  page = 页码  limit = 条数
        // 分页子集传过来的数据  page = 页码  limit = 条数
        page_limit(list) {
            console.log(list);
            let { page, limit } = list;
            this.currentPage = page;
            this.pageSize = limit;
            this.tableData = [];
            this.getFileList();
        },

        handleSizeChange(val) {
            console.log(`每页 ${val} 条`);
        },
        handleCurrentChange(val) {
            console.log(`当前页: ${val}`);
        },
        async getFileList() {
            let limit = this.pageSize;
            let page = this.currentPage;
            let contractId = this.buyingTaskId;
            if (contractId == undefined || contractId == '') {
                contractId = null;
            }
            const res = await taskList({
                limit,
                page,
                contractId
            });
            const data = res['data'];
            data.forEach((item) => {
                this.fetchProduceQuery(item['buyingTask']['id']).then((it) => {
                    item['buyingTask']['value'] = it;
                });
            });
            this.tableData = data;
            // console.log('==========================>', data);
            this.total = res.total;
            this.num = parseInt(this.total / limit + 1);
        },
        async fetchProduceQuery(item) {
            const response = await produceQuery({
                purchaseId: item
            });
            return response['data'];
        },
        async deleteFile(row, $index) {
            let id = row.id;
            const res = await deleteByingtask({
                id
            });
            if (res.code === 200) {
                this.$message.success('删除成功');
                this.getFileList();
                this.total = res.total;
            } else {
                this.$message.error('删除失败');
            }
            await this.getFileList();
        },
        async downloadFile(id) {
            const res = await fileDownload(id);
            download(res, 'test');
        },
        // 新增任务唐匡
        addTask() {
            this.dialogVisible = true;
        },
        // 获取合同表
        async getcontractList() {
            let res = await contractlist();
            let optionData = {};
            let optionList = [];
            if (res.code == 200) {
                res.data.forEach((item) => {
                    //   console.log(item);
                    optionData.lable = item.contractNo;
                    optionData.value = item.id;
                    optionList.push(optionData);
                    optionData = {};
                });
                // console.log(optionList);
                this.contraOptions = optionList;
            }
        },

        //确定新增
        async SendTaskData(taskFrom) {
            let res = await addByingTask(taskFrom);
            console.log(taskFrom);

            if (res.code == 200) {
                this.$message.success('新增成功');
                this.dialogVisible = false;
                this.taskFrom = {};
                this.total = res.total;
                this.getFileList();
            } else {
                this.$message.error(res.message);
            }
        },

        //  编辑 任务总量
        restAllTask() {
            this.$router.push('/CollaborativeManagement/orderManagement');
        },
        //  编辑任务记录
        restTask(sco, scope) {
            console.log(sco);
            console.log(scope);
            let data = {};
            data.name = sco.purchaseRecord.name;
            data.id = sco.purchaseRecord.id;
            data.timeFound = sco.purchaseRecord.timeCreated;
            console.log(scope)
            console.log(sco)
            this.maxNum = scope.row.buyingTask.num - scope.row.buyingTask.makeNum + sco.purchaseRecord.num;
            this.resetFrom = JSON.parse(JSON.stringify(data));
            this.resetdialogVisible = true;
        },
        async resetData(resetFrom) {
            console.log(this.resetFrom);
            let data = {};
            data.id = this.resetFrom.id;
            data.num = this.resetFrom.num;
            let res = await updateByingTaskList(data);

            if (res.code == 200) {
                this.$message.success('编辑成功');
                this.resetdialogVisible = false;
                this.resetFrom = {};
                this.total = res.total;
                this.getFileList();
            } else {
                this.$message.error(res.message);
            }
        },
        //取消编辑
        Cancel() {
            this.dialogVisible = false;
            this.resetdialogVisible = false;
            this.resetFrom = {};
            this.taskFrom = {};
        }
    }
};
</script>

<style lang="scss" scoped>
.button-gutter {
    margin: 20px 0;
}
.content_table {
    margin-top: 20px;
}
.reBack {
    float: right;

    margin-top: 10px;
    margin-bottom: 10px;
}
</style>
