import request from '@/utils/request'

export function produceQuery(params) {
    return request({
        url: '/buyingTask/purchase/record/list',
        method: 'get',
        params
    })
}

export function produceList(params) {
    return request({
        url: '/buyingTask/list',
        method: 'get',
        params
    })
}

export function produceSpeed(data) {
    return request({
        url: '/productionSchedule/make/update',
        method: 'post',
        data
    })
}